// import * as React from "react"

// import Layout from "../components/layoutPt"
// import Seo from "../components/seoPt"

// const NotFoundPage = () => (
//   <Layout>
//     <Seo title="404: Not found" lang="en" />
//     <h1>404: Not Found</h1>
//     <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
//   </Layout>
// )

// export default NotFoundPage

import React, { useEffect } from "react";
import Layout from "../components/layoutAr";
import Seo from "../components/seoAr.js";

const NotFoundPage = (props) => {
  console.log(props);
  useEffect(() => {
    if (props.path.startsWith("/")) {
      document.querySelector("body").classList.add("rightToLeft");
    }
  }, []);
  return (
    <Layout>
      <Seo title="404: Not found" lang="ar" />
      <h1>404 غير موجود</h1>
      <p>لقد اخترت طريق لا يؤدي لأي غاية.. خيبة!</p>
    </Layout>
  );
};

export default NotFoundPage;
